<mat-card>
    <mat-card-header>
        <mat-card-title>{{ message }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="cancel()" color="accent" tabindex="-1">Cancel</button>
            <button mat-raised-button *ngIf="actionType == 'confirm'" (click)="ok()" color="primary" tabindex="-1">Confirm</button>
            <button mat-raised-button *ngIf="actionType == 'delete'" (click)="ok()" color="warn" tabindex="-1">Delete</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>