import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environment';
import { emsUser, emsWorkspace } from 'src/models/emsModels';
import { NotificationBar } from 'src/services/notification.service';

@Component({
  selector: 'app-workspace-mgmt',
  templateUrl: './workspace-mgmt.component.html',
  styleUrls: ['./workspace-mgmt.component.scss']
})
export class WorkspaceMgmtComponent {
  notificationBar: NotificationBar;

  constructor(private title: Title, private snackBar: MatSnackBar, private fireAuth: AngularFireAuth, private db: AngularFireDatabase) {
    this.notificationBar = new NotificationBar(snackBar)
  }

  ngOnInit() {  
    this.title.setTitle("Workspaces Overview - LIUMEX");
  }

  async createNewWorkspace() {
    var workspaceDBRef = this.db.database.app.database(environment.emsDatabaseURL).ref('/workspaces');
    var workspace = new emsWorkspace();
    workspace.name = "Riot Games";
    workspace.owner = (await this.fireAuth.currentUser).uid;
    var workspaceID = workspaceDBRef.push(workspace).key
    var userDBRef = this.db.database.app.database(environment.emsDatabaseURL).ref('/user/' + (await this.fireAuth.currentUser).uid);
    var userUpdates = {};
    userUpdates['workspaces/' + workspaceID] = true;
    userDBRef.update(userUpdates)
    console.log("Created")
  }

  async getWorkspace() {
    var workspaceDBRef = this.db.database.app.database(environment.emsDatabaseURL).ref('/workspaces/-Nw7-irXW_QYtmUw3Eyu');
    await workspaceDBRef.once('value').then( x => {
      console.log(x.toJSON())
    })
  }
}
