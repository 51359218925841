import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LiumexAuthGuard } from '../services/liumex-auth.guard';
import { UserMgmtComponent } from './user-mgmt/user-mgmt.component';
import { WorkspaceMgmtComponent } from './workspace-mgmt/workspace-mgmt.component';

const routes: Routes = [
  {
    path: 'user',
    component: UserMgmtComponent,
    canActivate: [LiumexAuthGuard],
    data: {
      roles: [
        'admin',
        'support'
      ]
    }
  },
  {
    path: 'workspaces',
    component: WorkspaceMgmtComponent,
    canActivate: [LiumexAuthGuard],
    data: {
      roles: [
        'admin',
        'support'
      ]
    }
  },
  {
    path: '',
    component: DashboardComponent, 
    canActivate: [LiumexAuthGuard],
  },
  { 
    path: '**', 
    redirectTo: '',
  }
];

export const authNeeded = [
  "/user", "/workspaces"
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
