import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  isSignedIn = false;
  isAuthorized = false;

  constructor(private fireAuth: AngularFireAuth, private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Admin Panel - LIUMEX");
    this.fireAuth.onAuthStateChanged(() => {
      this.checkIfSignedIn();
    });
  }

  async checkIfSignedIn() {
    var user = await (this.fireAuth.currentUser);
    if (user) {
      this.isSignedIn = true;
      user.getIdTokenResult().then( idTokenResult => {
        var isAdmin = idTokenResult.claims["admin"];
        var isSupport = idTokenResult.claims["support"];
        if (isAdmin || isSupport) {
          this.isAuthorized = true;
        }
      });
    } 
    else {
      this.isSignedIn = false;
      this.isAuthorized = false;
    }
  }
}
