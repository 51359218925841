export class emsWorkspace {
    name: string; 
    owner: string; 
    teams: emsTeam[]; 
    competitions: emsCompetition[]; 
}

export class emsTeam {
    name: string; 
    logo: string; 
    url: string; 
    players: emsPlayer[];
}

export class emsPlayer {
    name: string; 
    logo: string; 
    url: string; 
}

export class emsCompetition {
    name: string; 
    owner: string; 
    url: string; 
}

export class emsUser {
    uid: string; 
    workspaces: { [workspaceID: string]: boolean }; 
    competitions: boolean[]; 
    teams: boolean[]; 
}