import { Component, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserRecord } from 'src/models/user';
import { NotificationBar } from 'src/services/notification.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditDialog {
  notificationBar: NotificationBar;
  userData: UserRecord;
  userLastTokenRefresh: string;
  userGroup = new FormGroup({
    usernameControl: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(16)]),
    emailControl: new FormControl('', [Validators.required, Validators.email]),
  });
  isVerified = false;
  isProcessingVerification = false;
  isProcessingUpdate = false;
  isProcessingClaim = false;
  isAdmin = false;
  isSupport = false;
  isPartner = false;
  isPremium = false;
  changesMade = false;
  
  constructor(public dialogRef: MatDialogRef<UserEditDialog>, private fireAuth: AngularFireAuth, private fireFunctions: AngularFireFunctions,
    private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.notificationBar = new NotificationBar(snackBar)
  }

  ngOnInit() {  
    this.dialogRef.backdropClick().subscribe( x => {
      this.dialogRef.close(this.changesMade);
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close(this.changesMade);
      }
    });
    this.userData = this.data.user;
    this.userLastTokenRefresh = this.data.user.metadata.lastRefreshTime;
    if (this.userData.customClaims != undefined) {
      this.isAdmin = this.userData.customClaims['admin'];
      this.isSupport = this.userData.customClaims['support'];
      this.isPartner = this.userData.customClaims['partner'];
      this.isPremium = this.userData.customClaims['premium'];
    }
    this.userGroup.controls.usernameControl.setValue(this.userData.displayName);
    this.userGroup.controls.emailControl.setValue(this.userData.email); 
    this.isVerified = this.userData.emailVerified;
  }

  getErrorUsernameChange() {
    if (this.userGroup.controls.usernameControl.hasError('maxlength')) {
      return 'Maximum 16 characters'
    }
    else return this.userGroup.controls.usernameControl.hasError('minlength') ? 'Use 4 characters or more' : '';
  }  

  getErrorEmailChange() {
    return this.userGroup.controls.emailControl.hasError('email') ? 'Not a valid email' : '';
  }

  async updateUser() {
    var idToken = "";
    this.isProcessingUpdate = true;
    await (await (this.fireAuth.currentUser)).getIdToken(true).then( res => {
      idToken = res;
    });
    var user = new UserRecord();
    user.uid = this.userData.uid;
    user.displayName = this.userGroup.controls.usernameControl.value;
    user.email = this.userGroup.controls.emailControl.value;
    user.emailVerified = this.userData.emailVerified;
    if (user.email != this.userData.email) {
      user.emailVerified = false;
    }
    this.fireFunctions.httpsCallable("admin-updateUser")({idToken, user}).subscribe( res => { 
      this.isProcessingUpdate = false;
      this.isVerified = user.emailVerified;
      this.notificationBar.show("User updated!");
      this.changesMade = true;
      }, err => {
        this.isProcessingUpdate = false;
        this.notificationBar.showUnknownError();
    });
  }

  async toggleVerification() {
    var idToken = "";
    this.isProcessingVerification = true;
    await (await (this.fireAuth.currentUser)).getIdToken(true).then( res => {
      idToken = res;
    });
    var uid = this.userData.uid;
    this.fireFunctions.httpsCallable("admin-toggleUserVerification")({idToken, uid}).subscribe( res => { 
      this.isProcessingVerification = false;
      this.isVerified = !this.isVerified;
      if (this.isVerified == true) {
        this.notificationBar.show("Email verified!");
      }
      else {
        this.notificationBar.show("Verification revoked!");
      }
      this.changesMade = true;
      }, err => {
        this.isProcessingVerification = false;
        this.notificationBar.showUnknownError();
    });
  }

  async toggleClaim(claim: string, event: MatSlideToggleChange) {
    var idToken = "";
    this.isProcessingClaim = true;
    await (await (this.fireAuth.currentUser)).getIdToken(true).then( res => {
      idToken = res;
    });
    var uid = this.userData.uid;
    if (claim == "admin") {
      this.fireFunctions.httpsCallable("admin-toggleAdminClaim")({idToken, uid}).subscribe( res => {
        this.isProcessingClaim = false;
        this.notificationBar.show("User roles updated!");
        this.changesMade = false;
      }, err => {
        this.isProcessingClaim = false;
        event.source.checked = !event.source.checked;
        if (err.code == "functions/permission-denied") {
          this.notificationBar.show("Missing permission!")
        }
        else {
          this.notificationBar.showUnknownError();
        } 
      });
    }
    if (claim == "support") {
      this.fireFunctions.httpsCallable("admin-toggleSupportClaim")({idToken, uid}).subscribe( res => {
        this.isProcessingClaim = false;
        this.notificationBar.show("User roles updated!");
        this.changesMade = false;
      }, err => {
        this.isProcessingClaim = false;
        event.source.checked = !event.source.checked;
        if (err.code == "functions/permission-denied") {
          this.notificationBar.show("Missing permission!")
        }
        else {
          this.notificationBar.showUnknownError();
        } 
      });
    }
    if (claim == "partner") {
      this.fireFunctions.httpsCallable("admin-togglePartnerClaim")({idToken, uid}).subscribe( res => {
        this.isProcessingClaim = false;
        this.notificationBar.show("User roles updated!");
        this.changesMade = false;
      }, err => {
        this.isProcessingClaim = false;
        event.source.checked = !event.source.checked;
        if (err.code == "functions/permission-denied") {
          this.notificationBar.show("Missing permission!")
        }
        else {
          this.notificationBar.showUnknownError();
        } 
      });
    }
    if (claim == "premium") {
      this.fireFunctions.httpsCallable("admin-togglePremiumClaim")({idToken, uid}).subscribe( res => {
        this.isProcessingClaim = false;
        this.notificationBar.show("User roles updated!");
        this.changesMade = false;
      }, err => {
        this.isProcessingClaim = false;
        event.source.checked = !event.source.checked;
        if (err.code == "functions/permission-denied") {
          this.notificationBar.show("Missing permission!")
        }
        else {
          this.notificationBar.showUnknownError();
        } 
      });
    }
  }
}
