<mat-card>
    <mat-card-header>
        <mat-card-title>Edit User</mat-card-title>
    </mat-card-header>
    <form [formGroup]="userGroup" (ngSubmit)="updateUser()">
        <mat-card-content>
            <mat-form-field appearance="fill" [hideRequiredMarker]="true">
                <mat-label>Username</mat-label>
                <input matInput type="email" [formControl]="userGroup.controls.usernameControl">
                <mat-error *ngIf="userGroup.controls.usernameControl.invalid">{{getErrorUsernameChange()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" [hideRequiredMarker]="true">
                <mat-label>Email Address</mat-label>
                <input matInput [type]="isVerified ? 'email' : 'text'" [formControl]="userGroup.controls.emailControl">
                <button mat-icon-button matSuffix type="button" (click)="toggleVerification()" *ngIf="isVerified && !isProcessingVerification" matTooltip="Revoke Verification">
                    <mat-icon>verified</mat-icon>
                </button>
                <button mat-icon-button matSuffix type="button" (click)="toggleVerification()" *ngIf="!isVerified && !isProcessingVerification" matTooltip="Verify Email">
                    <mat-icon>new_releases</mat-icon>
                </button>
                <button mat-icon-button matSuffix type="button" *ngIf="isProcessingVerification" disabled>
                    <mat-spinner></mat-spinner>
                </button>
                <mat-error *ngIf="userGroup.controls.emailControl.invalid">{{getErrorEmailChange()}}</mat-error>
            </mat-form-field>
            <mat-card-actions>
                <button mat-raised-button type="submit" *ngIf="!isProcessingUpdate" [disabled]="!userGroup.valid || userGroup.controls.usernameControl.value == userData.displayName && userGroup.controls.emailControl.value == userData.email" color="accent">Update</button>
                <button mat-raised-button type="submit" *ngIf="isProcessingUpdate" disabled><mat-spinner></mat-spinner></button>
            </mat-card-actions>
            <table>
                <tr>
                    <td>Admin</td>
                    <td *ngIf="!isProcessingClaim" class="claim-spinner" rowspan="4"></td>
                    <td *ngIf="isProcessingClaim" class="claim-spinner" rowspan="4"><mat-spinner></mat-spinner></td>
                    <td>Support</td>
                </tr>
                <tr>
                    <td><mat-slide-toggle (change)="toggleClaim('admin', $event)" [checked]="isAdmin" color="accent" [disabled]="isProcessingClaim" class="icon-slide-toggle"></mat-slide-toggle></td>
                    <td><mat-slide-toggle (change)="toggleClaim('support', $event)" [checked]="isSupport" color="accent" [disabled]="isProcessingClaim" class="icon-slide-toggle"></mat-slide-toggle></td>
                </tr>
                <tr>
                    <td>Partner</td>
                    <td>Premium</td>
                </tr>
                <tr>
                    <td><mat-slide-toggle (change)="toggleClaim('partner', $event)" [checked]="isPartner" color="accent" [disabled]="isProcessingClaim" class="icon-slide-toggle"></mat-slide-toggle></td>
                    <td><mat-slide-toggle (change)="toggleClaim('premium', $event)" [checked]="isPremium" color="accent" [disabled]="isProcessingClaim" class="icon-slide-toggle"></mat-slide-toggle></td>
                </tr>
            </table>
            <p>UID:
                <br>
                {{ userData.uid }}
            </p>
            <p>Joined:
                <br>
                {{ userData.metadata.creationTime | date: 'dd.MM.yyyy - HH:mm:ss' }}
            </p>
            <p>Last Sign In:
                <br>
                {{ userData.metadata.lastSignInTime | date: 'dd.MM.yyyy - HH:mm:ss' }}
            </p>
            <p>Last Token Refresh:
                <br>
                {{ userLastTokenRefresh | date: 'dd.MM.yyyy - HH:mm:ss' }}
            </p>
        </mat-card-content>
    </form>
</mat-card>
