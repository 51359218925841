import { Component, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserRecord } from 'src/models/user';
import { NotificationBar } from 'src/services/notification.service';
import { UserEditDialog } from '../user-edit/user-edit.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { ConfirmDialogDialog } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-user-mgmt',
  templateUrl: './user-mgmt.component.html',
  styleUrls: ['./user-mgmt.component.scss']
})
export class UserMgmtComponent {
  loadingAllUser = true;
  lastSync: Date = new Date();
  notificationBar: NotificationBar;
  displayedColumns: string[] = ['email', 'username', 'verified', 'uid', 'joined', 'token', 'edit', 'activation', 'delete'];
  userSource = new MatTableDataSource<UserRecord>;
  userForEdit: string;
  userForActivation: string;
  userForDelete: string;
  searchValue: string;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(private fireAuth: AngularFireAuth, private title: Title, private fireFunctions: AngularFireFunctions, private snackBar: MatSnackBar, public dialog: MatDialog) {
    this.notificationBar = new NotificationBar(snackBar)
  }

  ngOnInit() {  
    this.title.setTitle("User Overview - LIUMEX");
    this.getAllUser(true);
  }

  async getAllUser(showLoading: boolean) {
    if (showLoading == true) {
      this.userSource = null
      this.loadingAllUser = true;
    }
    var idToken = "";
    await (await (this.fireAuth.currentUser)).getIdToken(true).then( res => {
      idToken = res;
    });
    this.fireFunctions.httpsCallable("admin-getAllUser")(idToken).subscribe( res => {
      this.loadingAllUser = false;
      this.userSource = null
      this.userSource = new MatTableDataSource(res);
      this.userSource.sort = this.sort;
      this.userSource.paginator = this.paginator;
      if (this.searchValue != undefined) {
        this.userSource.filter = this.searchValue.trim().toLowerCase();
      }
      this.lastSync = new Date();
    }, err => {
      this.loadingAllUser = false;
      if (err.code == "functions/permission-denied") {
        this.notificationBar.show("Missing permission!")
      }
      else {
        this.notificationBar.showUnknownError();
      } 
    });
  }

  searchUser(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.userSource.filter = this.searchValue.trim().toLowerCase();
  }

  editUser(user: UserRecord) {
    this.userForEdit = user.uid;
    var dialogRef = this.dialog.open(UserEditDialog, {
      data: {
        user: user,
      }
    });
    return dialogRef.afterClosed().toPromise().then(res => {
      this.userForEdit = "";
      if (res == true) {
        this.getAllUser(true);
      }
    });
  }

  async toggleAccountActivation(user: UserRecord) {
    var uid = user.uid;
    this.userForActivation = uid;
    var idToken = "";
    await (await (this.fireAuth.currentUser)).getIdToken(true).then( res => {
      idToken = res;
    });
    var message = "Are you sure you want to disable " + user.email + "?"   
    if (user.disabled == true) {
      message = "Are you sure you want to enable " + user.email + "?"
    }
    var dialogRef = this.dialog.open(ConfirmDialogDialog, {
      data: {
        actionType: "confirm",
        message: message,
      }
    });
    return dialogRef.afterClosed().toPromise().then(async res => {
      if (res == true) {
        await (await (this.fireAuth.currentUser)).getIdToken(true).then( res => {
          idToken = res;
        });
        this.fireFunctions.httpsCallable("admin-toggleUserActivation")({idToken, uid}).subscribe( res => {
          if (user.disabled == false) {
            this.notificationBar.show("User disabled!");
          }
          else {
            this.notificationBar.show("User enabled!");
          }
          this.userForActivation = "";
          this.getAllUser(true);
        }, err => {
          this.userForActivation = "";
          if (err.code == "functions/permission-denied") {
            this.notificationBar.show("Missing permission!")
          }
          else {
            this.notificationBar.showUnknownError();
          } 
        });
      }
      else {
        this.userForActivation = "";
      }
    });
  }

  async deleteUser(user: UserRecord) {
    var uid = user.uid;
    this.userForDelete = uid;
    var idToken = "";
    var dialogRef = this.dialog.open(ConfirmDialogDialog, {
      data: {
        actionType: "delete",
        message: "Are you sure you want to delete " + user.email + "?",
      }
    });
    return dialogRef.afterClosed().toPromise().then(async res => {
      if (res == true) {
        await (await (this.fireAuth.currentUser)).getIdToken(true).then( res => {
          idToken = res;
        });
        this.fireFunctions.httpsCallable("admin-deleteAccount")({idToken, uid}).subscribe( res => {
          this.notificationBar.show("User deleted!");
          this.userForDelete = "";
          this.getAllUser(true);
        }, err => {
          this.userForDelete = "";
          if (err.code == "functions/permission-denied") {
            this.notificationBar.show("Missing permission!")
          }
          else {
            this.notificationBar.showUnknownError();
          } 
        });
      }
      else {
        this.userForDelete = "";
      }
    });
  }
}
