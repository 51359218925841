import { UserMetadata } from "firebase/auth";

export class UserRecord {
    uid: string;
    email: string;
    emailVerified: boolean;
    displayName: string;
    photoURL: string;
    disabled: boolean;
    metadata: UserMetadata;
    customClaims?: {
        [key: string]: any;
    };
}
