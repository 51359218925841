<mat-card>
  <mat-card-header>
    <mat-card-title>User Overview</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput (keyup)="searchUser($event)">
    </mat-form-field>
    <table mat-table [dataSource]="userSource" matSort >
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let user">
          <span *ngIf="!user.disabled">{{user.email}}</span>
          <span *ngIf="user.disabled" class="disabled-user">{{user.email}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="displayName">Username</th>
        <td mat-cell *matCellDef="let user">
          <span *ngIf="!user.disabled">{{user.displayName}}</span>
          <span *ngIf="user.disabled" class="disabled-user">{{user.displayName}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="uid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>UID</th>
        <td mat-cell *matCellDef="let user"> 
          <span *ngIf="!user.disabled">{{user.uid}}</span>
          <span *ngIf="user.disabled" class="disabled-user">{{user.uid}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="verified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="emailVerified">Verified</th>
        <td mat-cell *matCellDef="let user">
          <span *ngIf="!user.disabled">{{user.emailVerified}}</span>
          <span *ngIf="user.disabled" class="disabled-user">{{user.emailVerified}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="joined">
        <th mat-header-cell *matHeaderCellDef>Joined</th>
        <td mat-cell *matCellDef="let user">
          <span *ngIf="!user.disabled">{{user.metadata.creationTime | date: 'dd.MM.yyyy'}}</span>
          <span *ngIf="user.disabled" class="disabled-user">{{user.metadata.creationTime | date: 'dd.MM.yyyy'}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="token">
        <th mat-header-cell *matHeaderCellDef>Last Seen</th>
        <td mat-cell *matCellDef="let user">
          <span *ngIf="!user.disabled">{{user.metadata.lastRefreshTime | date: 'dd.MM.yyyy'}}</span>
          <span *ngIf="user.disabled" class="disabled-user">{{user.metadata.lastRefreshTime | date: 'dd.MM.yyyy'}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <mat-spinner *ngIf="userForEdit == user.uid"></mat-spinner>
          <mat-icon (click)="editUser(user)" *ngIf="userForEdit != user.uid" matTooltip="Edit User">edit</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="activation">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <mat-spinner *ngIf="userForActivation == user.uid"></mat-spinner>
          <mat-icon (click)="toggleAccountActivation(user)" *ngIf="!user.disabled && userForActivation != user.uid" matTooltip="Disable User">lock_open</mat-icon>
          <mat-icon (click)="toggleAccountActivation(user)" *ngIf="user.disabled && userForActivation != user.uid" matTooltip="Enable User">lock</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <mat-spinner *ngIf="userForDelete == user.uid"></mat-spinner>
          <mat-icon (click)="deleteUser(user)" *ngIf="userForDelete != user.uid" matTooltip="Delete User">delete</mat-icon>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="loading-spinner" *ngIf="this.loadingAllUser">
      <mat-spinner></mat-spinner>
    </div> 
    <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
    <p><mat-icon (click)="getAllUser(true)">sync</mat-icon> {{ lastSync | date: 'dd.MM.yyyy - HH:mm:ss' }}</p>
  </mat-card-content>
</mat-card>