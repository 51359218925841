<mat-toolbar color="primary">
    <button aria-label="Menu" mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
        <mat-icon>menu</mat-icon>
    </button>
    <h1 class="menu-titel" routerLink="">Admin Panel</h1>
    <div class="toolbar-spacer"></div>
    <button aria-label="Apps" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="LIUMEX-Apps">
        <mat-icon>apps</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu" class="app-menu">
        <span *ngFor="let extService of extServices">
            <a *ngIf="extService.available" target="_blank" href="{{ extService.url }}">
                <button mat-menu-item>
                    <div class="logo-container">
                        <img src="{{ extService.logo }}">
                    </div>    
                    <span>{{ extService.name }}</span>
                </button>
            </a>
        </span>
    </mat-menu>
    <button mat-raised-button *ngIf="!isSignedIn" (click)="signIn()" color="accent">Sign In</button>
    <div *ngIf="isSignedIn" class="account-menu-button">
        <img [matMenuTriggerFor]="accountMenu" src="{{ profilePicture }}">
        <mat-icon *ngIf="isAdmin || isSupport" [matMenuTriggerFor]="accountMenu" matTooltip="Staff" matTooltipPosition="before">token</mat-icon>
        <mat-icon *ngIf="isPartner && !isAdmin && !isSupport" [matMenuTriggerFor]="accountMenu" matTooltip="Partner" matTooltipPosition="before">verified</mat-icon>
        <mat-icon *ngIf="isPremium && !isPartner && !isAdmin && !isSupport"[matMenuTriggerFor]="accountMenu" matTooltip="Premium" matTooltipPosition="before">workspace_premium</mat-icon>        
    </div>
    <mat-menu #accountMenu="matMenu" class="account-menu">
        <div class="account-info" (click)="$event.stopPropagation()">
            <img src="{{ profilePicture }}">
            <p>{{ username }}</p>
        </div>
        <div class="theme-toggle" (click)="toggleDarkTheme()">
            <mat-slide-toggle [checked]="isDark" color="accent" class="icon-slide-toggle"></mat-slide-toggle>
            <p *ngIf="!isDark">Light Mode</p>
            <p *ngIf="isDark">Dark Mode</p>
        </div>
        <a target="_blank" href="https://account.liumex.com/auth">
            <button mat-menu-item>
                <mat-icon>manage_accounts</mat-icon>
                <span>Edit Account</span>
            </button>
        </a>
        <a *ngIf="isAdmin || isSupport" target="_blank" href="https://admin.liumex.com/">
            <button mat-menu-item>
                <mat-icon>settings</mat-icon>
                <span>Admin Panel</span>
            </button>
        </a>
        <button mat-menu-item (click)="signOut()">
            <mat-icon>logout</mat-icon>
            <span>Sign Out</span>
        </button>
    </mat-menu>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" autoFocus="false">
        <mat-nav-list>
            <a mat-list-item routerLink="" *ngIf="!isAdmin && !isSupport" (click)="sidenav._closeViaBackdropClick()">
                <mat-icon>home</mat-icon><span>Home</span>
            </a>
            <a mat-list-item routerLink="" *ngIf="isAdmin || isSupport" (click)="sidenav._closeViaBackdropClick()">
                <mat-icon>space_dashboard</mat-icon><span>Dashboard</span>
            </a>
            <mat-expansion-panel *ngIf="isAdmin || isSupport" #userPanel hideToggle>
                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                    <mat-panel-title>
                        <mat-icon>group</mat-icon><span>User</span>
                   </mat-panel-title>
                   <mat-icon>{{userPanel.expanded? 'expand_more' : 'expand_less'}}</mat-icon>
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <a mat-list-item routerLink="user" (click)="sidenav._closeViaBackdropClick()">
                        <span>Overview</span>
                    </a>
                    <a mat-list-item routerLink="" disabled="true">
                        <span>Partner</span>
                    </a>
                    <a mat-list-item routerLink="" disabled="true">
                        <span>Premium</span>
                    </a>
                    <a mat-list-item routerLink="" disabled="true">
                        <span>Promo Codes</span>
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>
            <a mat-list-item routerLink="workspaces" *ngIf="isAdmin || isSupport" (click)="sidenav._closeViaBackdropClick()">
                <mat-icon>workspace</mat-icon><span>Workspaces</span>
            </a>            
            <a mat-list-item routerLink="" *ngIf="isAdmin || isSupport" disabled="true">
                <mat-icon>support_agent</mat-icon><span>Support</span>
            </a>
            <a mat-list-item routerLink="" *ngIf="isAdmin" disabled="true">
                <mat-icon>payments</mat-icon><span>Payments</span>
            </a>
            <a mat-list-item routerLink="" *ngIf="isAdmin" disabled="true">
                <mat-icon>cloud</mat-icon><span>Services</span>
            </a>
            <a mat-list-item routerLink="" *ngIf="isAdmin" disabled="true">
                <mat-icon>construction</mat-icon><span>Maintenance</span>
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="site-container">
            <div class="site-content">
                <div class="site-loading-spinner" *ngIf="isLoading | async"><mat-spinner></mat-spinner></div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container> 