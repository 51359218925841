import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogDialog {

  actionType = "";
  message = "";

  constructor(public dialogRef: MatDialogRef<ConfirmDialogDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {  
    this.dialogRef.backdropClick().subscribe( x => {
      this.dialogRef.close(false);
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close(false);
      }
    });
    this.actionType = this.data.actionType;
    this.message = this.data.message;
  }

  ok() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
